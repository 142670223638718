.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.backgroundImage {
  object-fit:cover;
  width: 100%;
  @media (max-width: 768px) {
    object-fit:scale-down;
  }
}
