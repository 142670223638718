.root {
  text-align: center;
  /* padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 25px;
  padding-right: 25px; */
  /* @media (max-width: 768px){
    padding-top:
    padding-bottom: 30px;
  } */
}

.root a{
  padding: 30px;
  font-size: x-large;
  font-weight: bold;
  border-radius: 20px;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
}

@media (max-width: 760px) {
  .root {
    padding: none;
  }
  .root a{
    padding: 12px;
    font-size:12px;
    @media (max-width: 300px){
      padding: 7px;
      font-size:10px;
    }
  }
}