/* Common styles */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.33;
  font-weight: bold;
  color: var(--colorGrey900);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

/* Specific styles */
.h1 {
  font-size: 40px;

  /* If ´& + *´ if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 24px;
  }
  
  @media (max-width: 760px){
    font-size: 19px;
    text-align: center;
    padding-left: auto;
    padding-right: auto;
  }
  @media (max-width: 350px){
    font-size: 12px;
  }
}

.h2 {
  font-size: 30px;

  /* If ´& + *´ if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 16px;
  }
}

.h3 {
  font-size: 24px;
}

.h4 {
  font-size: 21px;
}

.h3,
.h4,
.h5,
.h6 {
  /* If ´& + *´ if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 8px;
  }
}
