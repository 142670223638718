@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 0 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.sectionContentFirst {
  padding: 10px 0;
  position: relative;
  object-fit: scale-down;
  @media (max-width: 300px) {
    padding: 0px
  }

  @media (min-width: 760px) {
    padding: calc(10vw ) 0;
  }
}

.sectionContentSecond {
  padding-top: 50px;
  position: relative;
  object-fit: scale-down;
  @media (min-width: 460px) {
    padding-top: 160px
  }
  @media (min-width: 1000px) {
    padding-top: 250px
  }
  @media (min-width: 1220px) {
    padding-top: 330px
  }
  @media (min-width: 1800px) {
    padding-top: 560px
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
